import { useState } from "react";

import "./styles.scss";
import PageTitle from "../../components/PageTitles";
import Button from "../../components/Buttons/Button";
import RadioList from "../../components/Lists/RadioList";
import LogoEmpty from "../../assets/icons/logos/logo-empty-gray.svg";
import LogoHallow from "../../assets/icons/logos/logo-hollow-gray.svg";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";

const UserSettingsMyOffersPage: React.FC = () => {
  const [chosenType, setChosenType] = useState("");
  const { setPrincipalScreen } = useDeskNavigation();

  return (
    <div className="user-settings">
      <div>
        <PageTitle
          title={"My Offers"}
          subtitle={"Settings"}
          onBackClick={() => setPrincipalScreen("/user-settings")}
        />
      </div>

      <div className="notifications-container">{"code here"}</div>
    </div>
  );
};

export default UserSettingsMyOffersPage;
