import fetchApi from "../fetchApi";

const deleteLikeMoment = async (token: string, momentId: string) => {
  return await fetchApi({
    service: "moments",
    method: "DELETE",
    endPoint: `/like/${momentId}`,
    token,
  });
};

export default deleteLikeMoment;
