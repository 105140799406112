export const formatTimeFromNowToDate = (targetDateString: Date) => {
  const targetDate = new Date(targetDateString);
  const currentDate = new Date();
  const diffMilliseconds = currentDate.getTime() - targetDate.getTime();

  const diffSeconds = Math.floor(diffMilliseconds / 1000);
  const diffMinutes = Math.floor(diffSeconds / 60);
  const diffHours = Math.floor(diffMinutes / 60);

  let result: string;

  if (diffHours > 0) {
    result = `${diffHours}h`;
  } else if (diffMinutes > 0) {
    result = `${diffMinutes}m`;
  } else {
    result = `${diffSeconds}s`;
  }

  return result;
};
