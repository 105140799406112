import fetchApi from "../fetchApi";

const getUserInfo = async (token: string) => {
  return await fetchApi({
    service: "user",
    endPoint: `/profile`,
    method: "GET",
    token,
  });
};

export default getUserInfo;
