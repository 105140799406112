import fetchApi from "../fetchApi";

const postLikeMoment = async (token: string, momentId: string) => {
  return await fetchApi({
    service: "moments",
    method: "POST",
    endPoint: `/like/${momentId}`,
    token,
  });
};

export default postLikeMoment;
