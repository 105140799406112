import React, { ReactNode, useEffect, useRef } from "react";
import "./styles.scss";

interface SlidUpProps {
  isOpen: boolean;
  children: ReactNode;
  classNameChildren?: string;
  title?: string;
  classNameContainer?: string;
  closeArea?: () => void;
}

const SlidUp: React.FC<SlidUpProps> = ({
  children,
  isOpen,
  classNameChildren,
  title,
  classNameContainer,
  closeArea,
}) => {
  const slidUpRef = useRef<HTMLDivElement>(null);

  const closeModal = () => {};
  useEffect(() => {
    const slidUp = slidUpRef.current;

    if (slidUp) {
      if (isOpen) {
        slidUp.style.display = "block";
        requestAnimationFrame(() => {
          slidUp.classList.add("open");
          slidUp.classList.remove("close");
        });
      } else {
        slidUp.classList.add("close");
        slidUp.classList.remove("open");

        setTimeout(() => {
          if (slidUp.classList.contains("close")) {
            slidUp.style.display = "none";
          }
        }, 350);
      }
    }
  }, [isOpen]);

  return (
    <div
      className={classNameContainer ?? ""}
      onClick={() => {
        if (closeArea) closeArea();
      }}
      id="slid-up-container"
      ref={slidUpRef}
    >
      <div className="slid-up-content" onClick={(e) => e.stopPropagation()}>
        <div className="handler-bar" />
        {title && <h4 className="slid-up-title">{title}</h4>}
        <div className={classNameChildren}>{children}</div>
      </div>
    </div>
  );
};

export default SlidUp;
