import React, { ReactNode, useEffect, useState } from "react";
import "./styles.scss";
import PageTitle from "../../components/PageTitles";
import ButtonNavigation from "../../components/Buttons/ButtonNavigation";
import InputText from "../../components/Inputs/InputText";
import SubText from "../../components/Texts/SubText";
import PhotosPlaceholder from "../../components/PhotosPlaceholder";
import chevronRightIcon from "../../assets/icons/navigationIcons/bx-chevron-right.svg";
import disclaimerIcon from "../../assets/icons/union-gray.svg";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import { UserInfoRequest } from "../../types/userInfoRequest";
import SlidUp from "../../components/Modals/SlidUp";
import getUserInfo from "../../api/userInfo/getUserInfo";
import getProfileDetails from "../../api/profileDetails/getProfileDetails";
import getUserPreferences from "../../api/userPreferences/getUserPreferences";
import Button from "../../components/Buttons/Button";
import patchProfileDetails from "../../api/profileDetails/patchProfileDetails";
import { textCapitalize } from "../../utils/textCapitalize";
import postUploadProfilePhotos from "../../api/profileDetails/postUploadProfilePhotos";
import deleteProfilePhotos from "../../api/profileDetails/deleteprofilePhotos";
import ListItemSecondary from "../../components/Lists/ListItemSecondary";
import { ageCalculate } from "../../utils/dateFormat";
import DropDown from "../../components/Lists/DropDown";
import ProfileIcon from "../../assets/icons/profile-icon-gray.svg";
import AirPlaneIconGray from "../../assets/icons/air-plane-gray.svg";
import LanguageIconGray from "../../assets/icons/language-icon-gray.svg";
import LogoRosesGray from "../../assets/icons/logos/logo-rose-icon-gray.svg";
import patchUserInfo from "../../api/userInfo/patchUserInfo";
import patchUserLocation from "../../api/userInfo/patchUserLocation";
import getUserLocationHistory from "../../api/userInfo/getUserLocationHistory";
import ListItemToggle from "../../components/Lists/ListItemToggle";
import ListItemLink from "../../components/Lists/ListItemLink";
import ListItemRadio from "../../components/Lists/ListItemRadio";
import InputRadio from "../../components/Inputs/InputRadio";
import InputCheckbox from "../../components/Inputs/InputCheckbox";

interface ListDetails {
  name: string;
  profileDetailId: number;
  type: DetailsType;
}

type DetailsType =
  | "eye_color"
  | "hair_color"
  | "nationality"
  | "ethnicity"
  | "language"
  | "gender"
  | "age"
  | "height"
  | "weight"
  | "language"
  | "occupation"
  | "marital_status"
  | "net_worth";

const UserSettingsProfilePage: React.FC = () => {
  const [activeSection, setActiveSection] = useState<string>("button1");
  const [photosAlbum, setPhotosAlbum] = useState<(File | null)[] | []>([]);
  const [isSlidUpVisible, setIsSlidUpVisible] = useState(false);
  const [isSlidUpWriteVisible, setIsSlidUpWriteVisible] = useState(false);

  const [userInfoRequest, setUserInfoRequest] =
    useState<UserInfoRequest | null>(null);
  const [eyeColorList, setEyeColorList] = useState<ListDetails[] | []>([]);
  const [hairColorList, setHairColorList] = useState<ListDetails[] | []>([]);
  const [nationalityList, setNationalityList] = useState<ListDetails[] | []>(
    []
  );
  const [ethnicityList, setEthnicityList] = useState<ListDetails[] | []>([]);
  const [languageList, setLanguageList] = useState<ListDetails[] | []>([]);
  const [genderList, setGenderList] = useState<string[] | []>([]);
  const [titleSlidUp, setTitleSlidUp] = useState("");
  const [userProfilePicture, setUserProfilePicture] = useState("");
  const [profileBirthday, setProfileBirthday] = useState("");
  const [profileHeight, setProfileHeight] = useState("");
  const [profileWeight, setProfileWeight] = useState("");
  const [openWriteInput, setOpenWriteInput] = useState("");
  const [selectedLocation, setSelectedLocation] = useState<string[]>([]);
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);
  const [modalSlidUpContent, setModalSlidUpContent] =
    useState<ReactNode | null>(null);
  const { setPrincipalScreen } = useDeskNavigation();
  const { userInfo } = useUserInfo();

  const [locationList, setLocationList] = useState<string[] | ListDetails[]>();
  const [valueSelectedState, setValueSelectedState] = useState<string>("");

  const handleSectionChange = (section: string) => {
    setActiveSection(section);
  };

  const updateLanguage = async (languages: string[]) => {
    if (languages) {
      const response = await patchUserInfo(userInfo.access_token, {
        profileLanguages: languages,
      });
    }
  };

  const updateLocation = async (location: string[]) => {
    //TODO - #01 refatorar update
    /*     location[0].location?.map(async (data: any) => {
      if (array[0] === data.place) {
        const response = await patchUserLocation(userInfo.access_token, data);
      }
    }); */

    let data;
    /* update */
    console.log(selectedLocation);

    locationList?.map(async (item: any) => {
      const place = [`${item.place}, ${item.country_code}`];
      if (place[0] === location[0]) {
        data = item;
        console.log(data, "deu certo");
        const response = await patchUserLocation(userInfo.access_token, data);
      }
    });
  };

  useEffect(() => {
    const requestProfileDetails = async () => {
      const response = await getProfileDetails(userInfo.access_token);

      setEyeColorList(
        response.res
          .filter((detail: ListDetails) => detail.type === "eye_color")
          .map((item: ListDetails) => textCapitalize(item.name))
      );

      setHairColorList(
        response.res
          .filter((detail: ListDetails) => detail.type === "hair_color")
          .map((item: ListDetails) => textCapitalize(item.name))
      );

      setNationalityList(
        response.res
          .filter((detail: ListDetails) => detail.type === "nationality")
          .map((item: ListDetails) => textCapitalize(item.name))
      );

      setEthnicityList(
        response.res
          .filter((detail: ListDetails) => detail.type === "ethnicity")
          .map((item: ListDetails) => textCapitalize(item.name))
      );

      setLanguageList(
        response?.res?.filter(
          (detail: ListDetails) => detail.type === "language"
        )
      );

      setGenderList(["Man", "Woman", "Non-binary", "Trans"]);
    };

    const requestPreferences = async () => {
      const response = await getUserPreferences(userInfo.access_token);
    };

    requestLocationHistory();
    requestPreferences();
    requestProfileDetails();
  }, [userInfo]);

  useEffect(() => {
    requestUserInfo();
  }, [isSlidUpVisible, isSlidUpWriteVisible, valueSelectedState]);

  const requestLocationHistory = async () => {
    const response = await getUserLocationHistory(userInfo.access_token);

    if (response.res.currentLocation) {
      const currentLocation = [
        `${response.res.currentLocation.place}, ${response.res.currentLocation.country_code}`,
      ];
      setSelectedLocation(currentLocation);
    }

    if (response.res.locationHistory) {
      const history = response.res.locationHistory.map(
        (item: any) => item.location
      );
      setLocationList(
        history
          .filter(
            (item: any, index: any, array: any) =>
              index ===
              array.findIndex((i: any) => i.locationId === item.locationId)
          )
          .map((item: ListDetails) => item)
      );
    }
  };

  const patchDetails = async (
    key: string,
    value: string | string[] | number
  ) => {
    return await patchProfileDetails(userInfo.access_token, key, value);
  };

  const requestUserInfo = async () => {
    const response = await getUserInfo(userInfo.access_token);

    setUserProfilePicture(response.res?.photos[0]?.url || ProfileIcon);

    setUserInfoRequest(response.res);
    const namesArray = response.res.languages.map(
      (language: ListDetails) => language.name
    );
    setSelectedLanguages(namesArray);
  };

  const applyChangeDetailRequestListed = async (
    detail: string,
    valueSelected: string
  ) => {
    let type =
      detail === "gender"
        ? "gender"
        : detail === "ethnicity"
        ? "profileEthnicity"
        : detail === "eye_color"
        ? "profileEyeColor"
        : detail === "hair_color"
        ? "profileHairColor"
        : detail === "nationality"
        ? "profileNationality"
        : false;

    let value =
      detail === "gender"
        ? valueSelected?.toUpperCase().replaceAll("-", "")
        : valueSelected?.toLowerCase().replaceAll(" ", "_");

    if (type) {
      await patchDetails(type, value);
    }

    setIsSlidUpVisible(false);
  };

  const changeListItemHandler = (toChange: DetailsType, selected: string) => {
    let value = selected;

    const detailsSlide =
      toChange === "gender"
        ? { list: genderList, title: "Gender" }
        : toChange === "ethnicity"
        ? { list: ethnicityList, title: "Ethnicity" }
        : toChange === "eye_color"
        ? { list: eyeColorList, title: "Eye Color" }
        : toChange === "hair_color"
        ? { list: hairColorList, title: "Hair Color" }
        : toChange === "nationality"
        ? { list: nationalityList, title: "Nationality" }
        : { list: [], title: "" };

    setTitleSlidUp(detailsSlide?.title);

    setIsSlidUpVisible(true);
    const selectedValueHandler = (value: any) => {
      setValueSelectedState(value);
    };

    setModalSlidUpContent(
      <>
        <div className="container-radio_radioList">
          <InputRadio
            elements={
              detailsSlide
                ? detailsSlide.list?.map((item: any) => {
                    return {
                      groupName: toChange,
                      inputLabel: `${textCapitalize(item)}`,
                      inputValue: `${item}`,
                    };
                  })
                : []
            }
            onChangeValueCallback={(e) => {
              value = e[0];
              selectedValueHandler(e[0]);
            }}
            defaultValueProp={value}
          />
        </div>
        <div className="container-button_radioList">
          <Button
            onClick={() => {
              applyChangeDetailRequestListed(toChange, value);
            }}
            buttonStyle="primary"
          >
            Apply
          </Button>
          <Button
            buttonStyle="secondary"
            onClick={() => setIsSlidUpVisible(false)}
          >
            Cancel
          </Button>
        </div>
      </>
    );
  };

  const addListLanguage = async (lang: string[]) => {
    const response = await patchDetails("profileLanguages", lang);

    switch (response.status) {
      case 200:
        setIsSlidUpVisible(false);
        break;
    }
  };

  const addSpecification = async () => {
    let key = openWriteInput === "birthday" ? "birthDate" : openWriteInput;
    let value =
      openWriteInput === "weight"
        ? Number(profileWeight)
        : openWriteInput === "height"
        ? Number(profileHeight)
        : profileBirthday;

    const response = await patchDetails(key, value);
    switch (response.status) {
      case 200:
        setIsSlidUpWriteVisible(false);
        break;
    }
  };

  const addPhotoHandler = async (files: (File | null)[]): Promise<void> => {
    setPhotosAlbum(files);
    const imageToUpload = files.filter((file) => file !== null);
    const photo = imageToUpload[imageToUpload.length - 1];
    if (photo) {
      await postUploadProfilePhotos(userInfo.access_token, photo);
      requestUserInfo();
    }
  };

  const removePhotoHandler = async (id: string) => {
    await deleteProfilePhotos(userInfo.access_token, id);
    requestUserInfo();
  };

  const formatHeight = () => {
    if (userInfoRequest?.height) {
      const feetConverter = Number(userInfoRequest?.height) * 0.0328084;
      const meters = `${
        userInfoRequest?.height.toString()[0]
      },${userInfoRequest?.height.toString().slice(1)}`;

      const feet = `${feetConverter.toString()[0]}’${feetConverter
        .toString()
        .slice(1)
        .substring(1, 3)}”`;

      return `${feet} / ${meters}`;
    } else {
      return "Add";
    }
  };

  const formatWeight = () => {
    if (userInfoRequest?.weight) {
      const poundConverter = Number(userInfoRequest?.weight) * 2.205;

      const kilo = `${userInfoRequest?.weight}kg`;
      const pounds = `${poundConverter.toFixed(2).replace(".", ",")}lb`;
      return `${kilo} / ${pounds}`;
    } else {
      return "Add";
    }
  };

  const selectLanguageHandler = () => {
    let selectedLanguageList: string[] = [];
    const handleCheckboxRightChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const value = event.target.value;

      if (event.target.checked) {
        if (!selectedLanguageList.includes(value)) {
          selectedLanguageList = [...selectedLanguageList, value];
        }
      } else {
        selectedLanguageList = selectedLanguageList.filter(
          (lang) => lang !== value
        );
      }
    };

    setTitleSlidUp("Language");

    languageList.map((item) => {
      console.log(item);
    });
    setModalSlidUpContent(
      <>
        <div className="container-radio_radioList">
          {/* {languageList.map(
            (
              lang: { name: string; profileDetailId: number; type: string },
              index
            ) => (
              <ListItemSecondary
                key={index}
                showLabel
                labelContent={textCapitalize(lang.name)}
                showCheckboxRight
                checkBoxRightHandler={handleCheckboxRightChange}
                checkboxRightValue={lang.name}
                // setCheckRightSelect={setSelectedLanguageList}
                // checkRightSelect={selectedLanguageList}
                showSeparator
                radioRightGroupName="language"
              />
            )
          )} */}
          <InputCheckbox
            elements={
              languageList
                ? languageList?.map((item: any) => {
                    return {
                      groupName: item.type,
                      inputLabel: `${textCapitalize(item.name)}`,
                      inputValue: `${item.name}`,
                    };
                  })
                : []
            }
            onChangeValueCallback={(option) => {
              console.log(option, "option");

              if (option) {
                if (!selectedLanguageList.includes(option[0])) {
                  selectedLanguageList = [...option];
                }
              } else {
                selectedLanguageList = selectedLanguageList.filter((lang) => {
                  console.log(lang, "lang");
                  return lang !== option;
                });
              }

              //setSelectedLanguages(option);
              console.log(selectedLanguageList);
              // handleCheckboxRightChange;
            }}
            className=""
            defaultValueProp={selectedLanguages}
          />
        </div>

        <Button
          onClick={async () => {
            await updateLanguage(selectedLanguages);
            setIsSlidUpVisible(false);
          }}
          buttonStyle="primary"
        >
          Apply
        </Button>
        <Button
          buttonStyle="secondary"
          onClick={() => setIsSlidUpVisible(false)}
        >
          Cancel
        </Button>
      </>
    );
    setIsSlidUpVisible(true);
  };

  const personalInfoData = [
    {
      labelContent: "Profile",
      valueContent: textCapitalize(userInfo.display_name) || "Add",
      clickAction: () => setPrincipalScreen("/user-settings-edit-profile"),
    },
    {
      labelContent: "Bio",
      valueContent: textCapitalize(userInfoRequest?.bio) || "Add",
      clickAction: () => setPrincipalScreen("/user-settings-edit-bio"),
    },
    {
      labelContent: "Gender",
      valueContent: textCapitalize(userInfoRequest?.gender) || "Add",
      clickAction: () => {
        setValueSelectedState(textCapitalize(userInfoRequest?.gender));
        changeListItemHandler(
          "gender",
          textCapitalize(userInfoRequest?.gender)
        );
      },
    },
    {
      labelContent: "Age",
      valueContent: ageCalculate(userInfoRequest?.birthDate) || "Add",
      clickAction: () => {
        setIsSlidUpWriteVisible(true);
        setOpenWriteInput("birthday");
      },
    },
    {
      labelContent: "Ethnicity",
      valueContent: textCapitalize(userInfoRequest?.ethnicity?.name) || "Add",
      clickAction: () => {
        setValueSelectedState(textCapitalize(userInfoRequest?.ethnicity?.name));
        changeListItemHandler(
          "ethnicity",
          textCapitalize(userInfoRequest?.ethnicity?.name)
        );
      },
    },
    {
      labelContent: "Height",
      valueContent: formatHeight() || "Add",
      clickAction: () => {
        setIsSlidUpWriteVisible(true);
        setOpenWriteInput("height");
      },
    },
    {
      labelContent: "Weight",
      valueContent: formatWeight() || "Add",
      clickAction: () => {
        setIsSlidUpWriteVisible(true);
        setOpenWriteInput("weight");
      },
    },
    {
      labelContent: "Language",
      valueContent: userInfoRequest?.languages?.length
        ? userInfoRequest.languages
            .map((lang) => textCapitalize(lang.name))
            .join(", ")
        : "Add",
      clickAction: selectLanguageHandler,
    },
    {
      labelContent: "Eye color",
      valueContent: textCapitalize(userInfoRequest?.eyeColor?.name) || "Add",
      clickAction: () => {
        setValueSelectedState(textCapitalize(userInfoRequest?.eyeColor?.name));
        changeListItemHandler(
          "eye_color",
          textCapitalize(userInfoRequest?.eyeColor?.name)
        );
      },
    },
    {
      labelContent: "Hair color",
      valueContent: textCapitalize(userInfoRequest?.hairColor?.name) || "Add",
      clickAction: () => {
        setValueSelectedState(textCapitalize(userInfoRequest?.hairColor?.name));
        changeListItemHandler(
          "hair_color",
          textCapitalize(userInfoRequest?.hairColor?.name)
        );
      },
    },

    {
      labelContent: "Nationality",
      valueContent: textCapitalize(userInfoRequest?.nationality?.name) || "Add",
      clickAction: () => {
        setValueSelectedState(
          textCapitalize(userInfoRequest?.nationality?.name)
        );
        changeListItemHandler(
          "nationality",
          textCapitalize(userInfoRequest?.nationality?.name)
        );
      },
    },
  ];

  const preferenceListData = [
    {
      showIconLeft: false,
      iconLeftContent: "",
      showIconBorderLeft: false,
      showLabel: true,
      labelContent: "Connect & Chat",
      showDescription: false,
      descriptionContent: "",
      showToggle: false,
      showIconRight: true,
      iconRightContent: chevronRightIcon,
      showValue: true,
      valueContent: "Free",
    },
    {
      showIconLeft: false,
      iconLeftContent: "",
      showIconBorderLeft: false,
      showLabel: true,
      labelContent: "Photos",
      showDescription: false,
      descriptionContent: "",
      showToggle: false,
      showIconRight: true,
      iconRightContent: chevronRightIcon,
      showValue: true,
      valueContent: "50",
    },
    {
      showIconLeft: false,
      iconLeftContent: "",
      showIconBorderLeft: false,
      showLabel: true,
      labelContent: "Packs",
      showDescription: false,
      descriptionContent: "",
      showToggle: false,
      showIconRight: true,
      iconRightContent: chevronRightIcon,
      showValue: true,
      valueContent: "100",
    },
  ];

  const profilePictureChangeHandler = () => {};

  const preserveConfidentiallyHandler = (event: boolean) => {};

  const smartPhotoHandler = (event: boolean) => {};

  const acceptRosesFromEveryoneHandler = (event: boolean) => {};

  return (
    <div className="user-settings-profile">
      <PageTitle
        title={"Profile"}
        subtitle={"Configuration"}
        onBackClick={() => setPrincipalScreen("/user-settings")}
        hasCloseIcon={false}
        className="padding-hor-16"
      />
      <ButtonNavigation
        setActiveSection={handleSectionChange}
        activeSection={activeSection}
        buttonCount={3}
        buttonLabels={["Info", "Photos", "Preference"]}
        className="margin-hor-24 margin-bottom-24"
      />
      {activeSection === "button1" && (
        <>
          <div className="profile-picture-container margin-hor-24">
            <div className="profile-picture-container__photo">
              <img src={userProfilePicture} alt="user profile" />
            </div>
            <Button
              buttonStyle="tertiary"
              onClick={profilePictureChangeHandler}
            >
              Profile Picture
            </Button>
          </div>
        </>
      )}

      {activeSection === "button1" && (
        <>
          <div className="list-container-personal-info margin-24">
            {personalInfoData.map((item, index) => (
              <ListItemLink
                key={index}
                hasSeparator={personalInfoData.length > index + 1}
                hasArrowRight
                highlightText={item.labelContent}
                onClick={item.clickAction}
                rightNodeContent={
                  <p className="text-detail">{item.valueContent}</p>
                }
              />
            ))}
          </div>
        </>
      )}

      {activeSection === "button2" && (
        <section
          className="album-photos-quantity-list-container margin-hor-24
          margin-bottom-24"
        >
          <div className="user-settings-photo-container ">
            <PhotosPlaceholder
              quantityFiles={9}
              photoAlbumCallback={addPhotoHandler}
              images={userInfoRequest?.photos}
              removeFileCallback={removePhotoHandler}
            />
            <p className="album-photos-quantity no-margin">
              {userInfoRequest?.photos?.length} / 9 photos
            </p>
          </div>

          <ListItemToggle
            toggleCallBack={preserveConfidentiallyHandler}
            hasSeparator
            highlightText="Preserve my Confidentiality"
            imageLeft={disclaimerIcon}
            isImageCircle
            simpleText="Conceal my facial identity"
          />

          <ListItemToggle
            toggleCallBack={smartPhotoHandler}
            hasSeparator
            highlightText="Smart Photo"
            imageLeft={disclaimerIcon}
            isImageCircle
            simpleText="Chooses best photo for you"
          />
          <SubText
            children={`We welcome sensual expression that's artistic and 
							sophisticated. Please avoid explicit content to maintain the 
							respectful and tasteful spirit of our community.`}
            icon={disclaimerIcon}
          />
        </section>
      )}

      {activeSection === "button3" && (
        <section className="user-settings-preferences margin-hor-24">
          <DropDown
            inputValueSet={selectedLocation}
            className="margin-bottom-24"
            setValueCallBack={(e) => {
              setSelectedLocation(e);
              // todo - #02 refatorar
              updateLocation(e);
            }}
            defaultValueProp={selectedLocation}
            placeholder="Select country"
            iconLeft={AirPlaneIconGray}
            options={
              locationList
                ? locationList.map((item: any) => {
                    return {
                      groupName: "country",
                      inputLabel: `${item.country}, ${item.country_code}`,
                      inputValue: `${item.place}, ${item.country_code}`,
                    };
                  })
                : []
            }
            textButton="Add location"
            label="Location"
            dropType="radio"
            buttonCallBack={() => setPrincipalScreen("/user-settings-location")}
          />

          <DropDown
            inputValueSet={selectedLanguages ? selectedLanguages : []}
            textButton="Add language"
            dropType="checkbox"
            label="Language"
            setValueCallBack={(e) => {
              setSelectedLanguages(e);
              updateLanguage(e);
            }}
            defaultValueProp={selectedLanguages}
            placeholder="Select language"
            iconLeft={LanguageIconGray}
            options={
              languageList
                ? languageList.map((language) => {
                    return {
                      groupName: "language",
                      inputLabel: `${language.name}`,
                      inputValue: `${language.name}`,
                    };
                  })
                : []
            }
            className="margin-vert-24"
            buttonCallBack={() =>
              setPrincipalScreen("/user-settings-language-profile")
            }
          />

          <div className="list-container margin-vert-24">
            <div className="list-container-settings">
              {preferenceListData.map((item, index, arr) => (
                <ListItemLink
                  key={index}
                  onClick={() => {}}
                  isImageCircle
                  hasArrowRight
                  hasSeparator={arr.length > index + 1}
                  highlightText={item.labelContent}
                  rightNodeContent={
                    <p className="text-detail">{item.valueContent}</p>
                  }
                />
              ))}
            </div>
          </div>
          <Button buttonStyle="tertiary">Add Content</Button>

          <ListItemToggle
            toggleCallBack={acceptRosesFromEveryoneHandler}
            highlightText="Accept roses requests from everyone"
            imageLeft={LogoRosesGray}
            isImageCircle
            simpleText="Allow Requests"
            className="margin-vert-16"
          />
        </section>
      )}

      <SlidUp
        classNameContainer="user-settings-slid-up"
        classNameChildren="list-item-slid-up "
        isOpen={isSlidUpVisible}
        title={titleSlidUp}
        closeArea={() => {
          setIsSlidUpVisible(false);
        }}
      >
        {modalSlidUpContent}
      </SlidUp>

      <SlidUp
        classNameContainer="user-settings-slid-up"
        classNameChildren="list-item-slid-up"
        isOpen={isSlidUpWriteVisible}
        title={openWriteInput}
        closeArea={() => {
          setIsSlidUpWriteVisible(false);
        }}
      >
        {openWriteInput === "birthday" ? (
          <InputText
            inputType="text"
            value={profileBirthday}
            onChangeInput={(e) => setProfileBirthday(e)}
            placeholder="YYYY/MM/DD"
            mask="9999/99/99"
          />
        ) : openWriteInput === "weight" ? (
          <InputText
            inputType="text"
            value={profileWeight}
            onChangeInput={(e) => setProfileWeight(e)}
            placeholder="Weight"
            // placeholder="YYYY/MM/DD"
            // mask="9999/99/99"
          />
        ) : openWriteInput === "height" ? (
          <InputText
            value={profileHeight}
            inputType="text"
            onChangeInput={(e) => setProfileHeight(e)}
            placeholder="Height"
            // mask="9999/99/99"
          />
        ) : (
          ""
        )}
        <Button onClick={() => addSpecification()} buttonStyle="primary">
          Apply
        </Button>
        <Button
          buttonStyle="secondary"
          onClick={() => setIsSlidUpWriteVisible(false)}
        >
          Cancel
        </Button>
      </SlidUp>
    </div>
  );
};

export default UserSettingsProfilePage;
