import "./styles.scss";
interface IRoseCredit {
  text: string;
  buttonStyle?:
  | "gold"
  className?: string;
  onClick?: () => void;
}

const RosesCredit: React.FC<IRoseCredit> = (props) => {
  const { text, buttonStyle = 'default', className, onClick } = props

  return (
    <button
      onClick={onClick}
      className={`
      roses-credit roses-credit--${buttonStyle} ${className ?? ''}
    `}>
      <div className="roses-credit__iconContainer">
        <span className="roses-credit__icon" />
      </div>
      {text}
    </button>
  )
}

export default RosesCredit;
