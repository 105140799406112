import React, { useState } from "react";
import "./styles.scss";
import LockGray from "../../../assets/icons/lock-icon-gray.svg";
import Button from "../../Buttons/Button";

interface ChatLockedProps {
  onClickButton: (roses: number) => void;
}

const ChatLocked: React.FC<ChatLockedProps> = ({
  onClickButton,
}: ChatLockedProps) => {
  const [selected, setSelected] = useState<string>("oneRoses");
  const [valueRoses, setValueRoses] = useState<number>(1);

  const handleChange = (type: string, value: number) => {
    setSelected(type)
    setValueRoses(value);
  };

  const handleButtonClick = (valueRoses: number) => {
    onClickButton(valueRoses);
  };

  return (
    <div className="locked-container margin-16">
      <div className="superior-container">
        <div className="locked-title-container">
          <img src={LockGray} alt="Lock Icon" className="lock-icon" />
          <h1 className="locked-title margin-top-16">CHAT LOCKED</h1>
          <h2 className="locked-subtitle">Unlock chat to begin interaction</h2>
        </div>
        <div className="unlock-container margin-bottom-16">
          <div
            style={{
              backgroundColor:
                selected === "oneRoses" ? "#333" : "var(--midnight)",
            }}
            className="unlock-inner"
          >
            <p className="unlock-text">Unlock Chat Connect</p>
            <div className="unlock-desc">
              <p className="roses-number">1 Rose</p>
              <input
                type="radio"
                className="custom-radio"
                value={1}
                checked={selected === "oneRoses"}
                onChange={(e) => {
                  handleChange("oneRoses", Number(e.target.value));
                }}
              />
            </div>
          </div>
        </div>
        <div className="unlock-container secondary">
          <div
            style={{
              backgroundColor:
                selected === "twentyFiveRoses" ? "#333" : "var(--midnight)",
            }}
            className="unlock-inner"
          >
            <p className="unlock-text">Schedule Voice Connect</p>
            <div className="unlock-desc">
              <p className="roses-number">25 Roses</p>
              <input
                type="radio"
                className="custom-radio"
                value={25}
                checked={selected === "twentyFiveRoses"}
                onChange={(e) => {
                  handleChange("twentyFiveRoses", Number(e.target.value));
                }}
              />
            </div>
          </div>
        </div>

      </div>
      <Button
        buttonStyle="primary"
        onClick={() => handleButtonClick(valueRoses)}
        className="margin-bottom-16"
      >
        SEND REQUEST
      </Button>
    </div>
  );
};

export default ChatLocked;
