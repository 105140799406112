import { servicesApi } from "../fetchApi/services-api";

export interface IPostMomentBody {
  price: number;
  file: File[];
}

const postMoment = async (token: string, body: IPostMomentBody) => {
  const apiRoute = `${servicesApi("moments")}`;

  const formData = new FormData();

  if (!body.file || body.file.length === 0) {
    throw new Error("Error: No files provided.");
  }

  formData.append("price", JSON.stringify(body.price));

  body.file.forEach((file) => {
    formData.append("file", file);
  });

  try {
    const response = await fetch(apiRoute, {
      method: "POST",
      body: formData,
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await response.json();
    return { res: data, status: response.status };
  } catch (error) {
    console.error("Error in postMoment:", error);
    throw error;
  }
};

export default postMoment;
