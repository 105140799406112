import React from "react";
import "./styles.scss";
import { IMomentsUnseen } from "../../../types/moments";

interface MomentsSpheresProps {
  clickMoment: (moment: string) => void;
  addMomentClick?: (a: any) => void;
  className?: string;
  momentsDetails: {
    momentsUnseen: IMomentsUnseen[];
    momentsSeen: IMomentsUnseen[];
  };
}

const MomentsSpheres: React.FC<MomentsSpheresProps> = (props) => {
  const { clickMoment, className, momentsDetails, addMomentClick } = props;

  return (
    <div
      id="moments-spheres-component"
      className={`padding-vert-16 ${className ?? ""}`}
    >
      {addMomentClick && (
        <div
          className="moments-spheres-component__add-moments-container"
          onClick={addMomentClick}
        >
          <span className="icon icon-plus icon-sm"></span>
        </div>
      )}

      {momentsDetails?.momentsUnseen.map((moment, index) => (
        <div
          key={index}
          className={`moment-wrapper new-moment-available`}
          onClick={() => clickMoment(moment.userId)}
        >
          <img
            className="moment-image padding-4"
            src={moment.profile.photos[0].url}
            alt="moment"
          />
        </div>
      ))}
      {momentsDetails?.momentsSeen.map((moment, index) => (
        <div
          key={index}
          className={`moment-wrapper moment-was-seen`}
          onClick={() => {
            clickMoment(moment.userId);
          }}
        >
          <img
            className="moment-image padding-4"
            src={moment.profile.photos[0].url}
            alt="moment"
          />
        </div>
      ))}
    </div>
  );
};

export default MomentsSpheres;
