import { useState } from "react";
import Header from "../../components/Headers/Header";
import "./styles.scss";
import LockIcon from "../../assets/icons/lock-icon-gray.svg";
import InputText from "../../components/Inputs/InputText";
import Button from "../../components/Buttons/Button";
import SubText from "../../components/Texts/SubText";
import { useLoginSignUpNavigation } from "../../hooks/useLoginSignUpNavigation";
import ResetPassword from "../../api/auth/postPasswordReset";
import { IToast } from "../../types/toast";
import Toast from "../../components/Toast";
import { useLocation, useNavigate } from "react-router-dom";
import CheckIconCirclePink from "../../assets/icons/check-circle-icon-pink.svg";
import UpdatePassword from "../../api/auth/postPasswordUpdate";

const ResetPasswordPage: React.FC = () => {
  const [currentEmail, setCurrentEmail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newUserPassword, setNewUserPassword] = useState("");
  const [newUserConfirmPassword, setNewUserConfirmPassword] = useState("");
  const [newUserPasswordWarning, setNewUserPasswordWarning] = useState(false);
  const [newUserConfirmPasswordWarning, setNewUserConfirmPasswordWarniing] =
    useState(false);
  const [toastShow, setToastShow] = useState(false);
  const [toastConfig, setToastConfig] = useState<IToast>({
    type: undefined,
    title: "",
    description: "",
  });

  const [emailInputWarning, setEmailInputWarning] = useState(false);
  const { setComponent } = useLoginSignUpNavigation();
  const location = useLocation();

  // Use URLSearchParams to get the query parameter
  const queryParams = new URLSearchParams(location.search);
  const tokenParams = queryParams.get("token");
  const navigate = useNavigate();

  const resetPasswordSubmit = async () => {
    const requestReset = await ResetPassword({ email: currentEmail });

    //open toast with message
    if (requestReset.res.message) {
      setToastConfig({
        description: requestReset.res.message,
        title: "Reset password",
        type: "success",
      });

      setToastShow(true);

      setTimeout(() => {
        setToastShow(false);
      }, 3000);
    }

    if (!currentEmail) {
      setToastConfig({
        description: "Check your credential and, please, try again",
        title: "Reset password",
        type: "error",
      });

      setEmailInputWarning(true);
      setToastShow(true);

      setTimeout(() => {
        setToastShow(false);
        setEmailInputWarning(false);
      }, 3000);
    }
  };

  const cancelSubmit = () => {
    setComponent("/account-login");
    if (tokenParams) {
      navigate("/");
    }
  };

  const resetPasswordWithToken = async () => {
    if (
      (!newUserPassword && !newUserConfirmPassword) ||
      newUserPassword != newUserConfirmPassword
    ) {
      setToastConfig({
        description: "Check your credentials and, please, try again",
        title: "Reset password",
        type: "error",
      });

      setNewUserConfirmPasswordWarniing(true);
      setNewUserPasswordWarning(true);

      setToastShow(true);

      setTimeout(() => {
        setToastShow(false);
        setNewUserConfirmPasswordWarniing(false);
        setNewUserPasswordWarning(false);
      }, 3000);
    }
    if (!newUserConfirmPassword || newUserConfirmPassword.length < 8) {
      setToastConfig({
        description: "Check your credentials and, please, try again",
        title: "Reset password",
        type: "error",
      });

      setNewUserConfirmPasswordWarniing(true);

      setToastShow(true);

      setTimeout(() => {
        setToastShow(false);
        setNewUserConfirmPasswordWarniing(false);
      }, 3000);
    }
    if (!newUserPassword || newUserPassword.length < 8) {
      setToastConfig({
        description: "Check your credentials and, please, try again",
        title: "Reset password",
        type: "error",
      });

      setNewUserPasswordWarning(true);

      setToastShow(true);

      setTimeout(() => {
        setToastShow(false);
        setNewUserPasswordWarning(false);
      }, 3000);
    }

    if (newUserPassword === newUserConfirmPassword) {
      if (tokenParams) {
        const update = await UpdatePassword({
          token: tokenParams,
          newPassword: newUserPassword,
        });

        if (update.res.message === "Password has been updated successfully.") {
          setToastConfig({
            description: update.res.message,
            title: "Reset password",
            type: "success",
          });

          setToastShow(true);

          setTimeout(() => {
            setToastShow(false);
            navigate("/account-login");
          }, 3000);
        } else {
          setToastConfig({
            description: update.res.message,
            title: "Reset password",
            type: "error",
          });

          setToastShow(true);

          setTimeout(() => {
            setToastShow(false);
          }, 3000);
        }
      }
    }
  };

  return (
    <div className="reset-password">
      {tokenParams ? (
        <>
          <div className="app-link">
            <Header
              headerIcon="roses-logo"
              backButtonClick={() => setComponent("/")}
              title={"Reset Password"}
              subTitle={
                <>
                  Your password should be at least <br /> 8 characters long
                </>
              }
            />

            <div className="separator-margin" />
            <InputText
              value={newUserPassword}
              placeholder="New Password"
              onChangeInput={(e) => setNewUserPassword(e)}
              inputType="password"
              isWarning={newUserPasswordWarning}
            />
            <div className="separator-margin" />

            <InputText
              value={newUserConfirmPassword}
              placeholder="Confirm New Password"
              onChangeInput={(e) => setNewUserConfirmPassword(e)}
              inputType="password"
              isWarning={newUserConfirmPasswordWarning}
            />
            <SubText icon={LockIcon} altIcon="clock icon">
              Never share your credentials with anyone.
            </SubText>
            <div className="buttons-container">
              <Button buttonStyle="primary" onClick={resetPasswordWithToken}>
                Reset password
              </Button>
              <Button buttonStyle="quaternary" onClick={cancelSubmit}>
                Cancel
              </Button>
            </div>

            <Toast
              type={toastConfig.type}
              isVisible={toastShow}
              setIsVisible={setToastShow}
              title={toastConfig.title}
              description={toastConfig.description}
            />
          </div>
        </>
      ) : (
        <>
          <Header
            headerIcon="back-button"
            backButtonClick={() => setComponent("/account-login")}
            title={"Reset Password"}
            subTitle={
              <>
                Enter your email, we will send you a link to reset your password
              </>
            }
          />
          <InputText
            value={currentEmail}
            placeholder="Your email"
            onChangeInput={(e) => setCurrentEmail(e)}
            inputType="text"
            isWarning={emailInputWarning}
          />

          <div className="separator-margin" />

          <SubText icon={LockIcon} altIcon="clock icon">
            Never share your credentials with anyone.
          </SubText>

          <div className="buttons-container">
            <Button buttonStyle="primary" onClick={resetPasswordSubmit}>
              Reset password
            </Button>
            <div className="separator-margin" />

            <Button buttonStyle="quaternary" onClick={cancelSubmit}>
              Cancel
            </Button>
          </div>

          <Toast
            type={toastConfig.type}
            isVisible={toastShow}
            setIsVisible={setToastShow}
            title={toastConfig.title}
            description={toastConfig.description}
          />
        </>
      )}
    </div>
  );
};

export default ResetPasswordPage;
