import React from "react";
import "./styles.scss";

interface RadioListOptions {
  title: string;
  price: string;
  description: string;
  value: string;
  icon: string;
  groupName: string;
  checked?: boolean;
  onChange: (value: string) => void;
}

const InputRadioSub: React.FC<RadioListOptions> = (props) => {
  const {
    title,
    price,
    description,
    value,
    icon,
    groupName,
    checked,
    onChange,
  } = props;

  return (
    <label id="option">
      <div className="option-title">
        <h1 className="title">
          {" "}
          <b>{title.split(" ")[0]}</b> {title.split(" ")[1]}
        </h1>
        <img src={icon} alt="" />
        <div className="input-select">
          {value === "bloom" && <div className="popular">Popular</div>}

          <input
            type="radio"
            value={value}
            name={groupName}
            defaultChecked={checked}
            onChange={() => onChange(value)}
            className="input-radio"
          />
        </div>
      </div>

      <div className="option-price">
        <span className="price">{price}</span>
        /mo
      </div>

      <div className="option-text">{description}</div>
    </label>
  );
};

export default InputRadioSub;
