import React, { useState, useRef, useCallback } from "react";
import "./styles.scss";
import ArrowLeft from "../../../assets/icons/navigationIcons/arrow-left-gray.svg";
import ArrowRight from "../../../assets/icons/navigationIcons/bx-chevron-right.svg";
import Button from "../../Buttons/Button";
import { IButtonStyles } from "../../../types/buttonStyles";

interface SlideCarouselProps {
  slides: Slides[];
  hasArrows?: boolean;
  emptyDotsColors?: string;
  fillDotsColor?: string;
  supButtonStyle?: IButtonStyles;
  supTextButton?: string;
  supButtonOnClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  subButtonStyle?: IButtonStyles;
  subTextButton?: string;
  subButtonOnClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  lastSlideButtonStyle?: IButtonStyles;
  lastSlideTextButton?: string;
  lastSlideButtonOnClick?:
    | React.MouseEventHandler<HTMLButtonElement>
    | undefined;
  loopSlides?: boolean;
  className?: string;
}

interface Slides {
  id?: number;
  title: string;
  subtitle: string;
  backgroundImage: string;
  icon: string;
}

const SlideCarousel: React.FC<SlideCarouselProps> = (props) => {
  const {
    slides,
    fillDotsColor = "var(--pink)",
    emptyDotsColors = "var(--charcoal)",
    supButtonOnClick,
    subButtonOnClick,
    supButtonStyle,
    subButtonStyle,
    supTextButton,
    subTextButton,
    loopSlides = false,
    lastSlideButtonOnClick,
    lastSlideButtonStyle,
    lastSlideTextButton,
    hasArrows = true,
    className,
  } = props;
  const [currentIndex, setCurrentIndex] = useState(0);
  const startX = useRef<number | null>(null);

  const goToNextSlide = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      loopSlides
        ? (prevIndex + 1) % slides.length
        : Math.min(prevIndex + 1, slides.length - 1)
    );
  }, [slides.length, loopSlides]);

  const goToPreviousSlide = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      loopSlides
        ? prevIndex === 0
          ? slides.length - 1
          : prevIndex - 1
        : Math.max(prevIndex - 1, 0)
    );
  }, [slides.length, loopSlides]);

  const handleMouseDown = useCallback((e: React.MouseEvent) => {
    startX.current = e.clientX;
  }, []);

  const handleMouseMove = useCallback(
    (e: React.MouseEvent) => {
      if (startX.current === null) return;

      const diffX = e.clientX - (startX.current || 0);
      if (diffX > 50) {
        goToPreviousSlide();
        startX.current = null;
      } else if (diffX < -50) {
        goToNextSlide();
        startX.current = null;
      }
    },
    [goToNextSlide, goToPreviousSlide]
  );

  const handleMouseUp = useCallback(() => {
    startX.current = null;
  }, []);

  const handleTouchStart = useCallback((e: React.TouchEvent) => {
    startX.current = e.touches[0].clientX;
  }, []);

  const handleTouchMove = useCallback(
    (e: React.TouchEvent) => {
      if (startX.current === null) return;

      const diffX = e.touches[0].clientX - (startX.current || 0);
      if (diffX > 50) {
        goToPreviousSlide();
        startX.current = null;
      } else if (diffX < -50) {
        goToNextSlide();
        startX.current = null;
      }
    },
    [goToNextSlide, goToPreviousSlide]
  );

  const handleTouchEnd = useCallback(() => {
    startX.current = null;
  }, []);

  return (
    <div
      id="slide-carousel"
      className={className ?? ""}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div id="slide-carousel-images">
        {slides.map((slide, index) => (
          <div key={index} className="main-slide-wrapper">
            <img
              className={`slide-carousel-slide ${
                index === currentIndex ? "active" : ""
              }`}
              key={index}
              src={slide.backgroundImage}
              alt=""
            />
            <div
              className={`text-container ${
                index === currentIndex ? "active" : ""
              } ${
                subButtonStyle && subButtonOnClick && subButtonStyle
                  ? "margin-bottom-for-bottoms"
                  : ""
              }`}
            >
              <img src={slide.icon} alt="Icon" />
              <h2 dangerouslySetInnerHTML={{ __html: slide.title }} />
              <p dangerouslySetInnerHTML={{ __html: slide.subtitle }} />
            </div>
          </div>
        ))}

        {hasArrows && (
          <button className="carousel-button prev" onClick={goToPreviousSlide}>
            <img src={ArrowLeft} alt="arrow left" />
          </button>
        )}
        {hasArrows && (
          <button className="carousel-button next" onClick={goToNextSlide}>
            <img src={ArrowRight} alt="arrow right" />
          </button>
        )}

        <div className="footer-dots-button">
          <div className="pagination-dots-wrapper">
            {slides.map((_, index) => (
              <div
                onClick={() => setCurrentIndex(index)}
                key={index}
                className={`pagination-dot ${
                  index === currentIndex ? "current-dot" : ""
                } `}
                style={{
                  borderColor:
                    index === currentIndex ? fillDotsColor : emptyDotsColors,
                }}
              />
            ))}
          </div>
          <div className="container-button-carousel margin-hor-24">
            {slides.length !== currentIndex + 1 ? (
              <>
                {supButtonStyle && supButtonOnClick && supTextButton && (
                  <Button
                    buttonStyle={supButtonStyle}
                    onClick={supButtonOnClick}
                  >
                    {supTextButton}
                  </Button>
                )}
                {subButtonStyle && subButtonOnClick && subButtonStyle && (
                  <Button
                    buttonStyle={subButtonStyle}
                    onClick={subButtonOnClick}
                  >
                    {subTextButton}
                  </Button>
                )}
              </>
            ) : (
              <>
                {lastSlideTextButton &&
                  lastSlideButtonOnClick &&
                  lastSlideButtonStyle && (
                    <Button
                      buttonStyle={lastSlideButtonStyle}
                      onClick={lastSlideButtonOnClick}
                    >
                      {lastSlideTextButton}
                    </Button>
                  )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlideCarousel;
