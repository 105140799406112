import { useState } from "react";
import "./styles.scss";
import PageTitle from "../../components/PageTitles";
import Button from "../../components/Buttons/Button";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import InputRadioSub from "../../components/Inputs/InputRadioSub";
import infoIcon from "../../assets/icons/union-circle-gray.svg";

const UserSettingsSubscriptionPage: React.FC = () => {
  const [chosenType, setChosenType] = useState("bloom");
  const { setPrincipalScreen } = useDeskNavigation();
  const handleOptionChange = (value: string) => {
    console.log(value);
    setChosenType(value);
  };
  const subscriptionOptions = [
    {
      title: "Seed Basic",
      price: "FREE",
      description:
        "Your starting point, where new connections begin to sprout.",
      value: "seed",
    },
    {
      title: "Bloom Global",
      price: "$259",
      description:
        "Enjoy all features from Seed Basic, plus connect with people from all over the world.",
      value: "bloom",
    },
    {
      title: "Roses VIP",
      price: "$495",
      description:
        "Enjoy all features from Bloom Global, plus exclusive VIP perks.",
      value: "roses",
    },
  ];

  return (
    <div className="user-settings-subscription">
      <PageTitle
        title={"Subscription"}
        subtitle={"Manage Plans"}
        onBackClick={() => setPrincipalScreen("/user-settings")}
      />
      <div className="roses-container">
        {subscriptionOptions.map((option) => (
          <InputRadioSub
            title={option.title}
            price={option.price}
            description={option.description}
            value={option.value}
            icon={infoIcon}
            groupName={"options"}
            checked={option.value === chosenType}
            onChange={handleOptionChange}
          />
        ))}
        <Button
          buttonStyle="primary"
          onClick={() => {}}
          className="margin-hor-24"
        >
          Subscribe
        </Button>
      </div>
    </div>
  );
};

export default UserSettingsSubscriptionPage;
