import { useState } from "react";

import "./styles.scss";
import PageTitle from "../../components/PageTitles";

import { useDeskNavigation } from "../../hooks/useDeskNavigation";

const UserSettingsNotificationsPage: React.FC = () => {
  const [chosenType, setChosenType] = useState("");
  const { setPrincipalScreen } = useDeskNavigation();

  return (
    <div className="user-settings">
      <PageTitle
        title={"Notifications"}
        subtitle={"Settings"}
        onBackClick={() => setPrincipalScreen("/user-settings")}
      />
      <div className="notifications-container">{"code here"}</div>
    </div>
  );
};

export default UserSettingsNotificationsPage;
