import React, { useCallback, useEffect, useRef, useState } from "react";
import "./styles.scss";
import MomentsCards from "../MomentsCards";
import Progressbar from "../../Progressbar";
import CloseIcon from "../../../assets/icons/closeIcons/close-icon-peach.svg";
import photoDefault from "../../../assets/icons/profile-icon-gray.svg";
import { useModal } from "../../../hooks/useModal";
import { useUserInfo } from "../../../hooks/userInfo";
import { IMoments } from "../../../types/moments";
import getUserMoments from "../../../api/moments/getUserMoments";
import postMomentView from "../../../api/moments/postMomentView";
import getMomentsUnseen from "../../../api/moments/getMomentsUnseen";
import getMomentSeen from "../../../api/moments/getMomentsSeen";
import getPublicDetails from "../../../api/publicDetails/getProfileDetails";
import { IUserPublicInfo } from "../../../types/userInfo";
import Loading from "../../Loading";
import postLikeMoment from "../../../api/moments/postLikeMoment";
import deleteLikeMoment from "../../../api/moments/deleteLikeMoment";
import postMomentPay from "../../../api/moments/postMomentPay";
import CloseIconPink from "../../../assets/icons/closeIcons/close-icon-pink.svg";
import CheckSimpleGreen from "../../../assets/icons/check-simple-green.svg";
import Button from "../../Buttons/Button";
import { useDeskNavigation } from "../../../hooks/useDeskNavigation";

interface MomentsTrackProps {
  className?: string;
  userIdToStarted: string;
  allUnseenUserIds: string[];
  allSeenUserIds: string[];
}

const MomentsTrack: React.FC<MomentsTrackProps> = (props) => {
  const { allUnseenUserIds, userIdToStarted, className, allSeenUserIds } =
    props;

  const { userInfo } = useUserInfo();
  const { setOpenUseModal, setModalContent } = useModal();
  const { setPrincipalScreen } = useDeskNavigation();

  const [profileAvatar, setProfileAvatar] = useState("");
  const [currentProgress, setCurrentProgress] = useState(0);
  const [momentsByUserId, setMomentsByUserId] = useState<IMoments[]>([]);
  const [creatorMomentInfo, setCreatorMomentInfo] = useState(
    {} as IUserPublicInfo
  );
  const [userIdList, setUserIdList] = useState<string[]>([]);
  const [isLoadingMoments, setIsLoadingMoments] = useState(true);
  const [userIdShowing, setUserIdShowing] = useState(userIdToStarted);
  const [isPausedMoment, setIsPausedMoment] = useState(false);
  const [paymentMomentStatus, setPaymentMomentStatus] = useState<
    "success" | "error" | undefined
  >(undefined);
  const [momentAbleToShow, setMomentAbleToShow] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const startX = useRef<number | null>(null);

  useEffect(() => {
    setUserIdList(() => {
      const combinedList = [
        userIdToStarted,
        ...allUnseenUserIds,
        ...allSeenUserIds,
      ];
      return Array.from(new Set(combinedList));
    });
  }, [userIdToStarted, allUnseenUserIds, allSeenUserIds]);

  useEffect(() => {
    setIsLoadingMoments(true);
    Promise.all([
      getMomentsByUserId(userIdShowing),
      getMomentCreatorInfo(userIdShowing),
      momentsSeenRequest(),
    ]).finally(() => setIsLoadingMoments(false));
  }, [userIdShowing, userInfo.access_token]);

  useEffect(() => {
    const currentMoment = momentsByUserId[currentProgress];
    const canShowMoment = !currentMoment?.paidContent || currentMoment?.hasPaid;
    setMomentAbleToShow(canShowMoment);
  }, [currentProgress, momentsByUserId]);

  const momentView = async (momentId: string) => {
    const request = await postMomentView(userInfo.access_token, momentId);
    console.log(request);
  };

  const getMomentsByUserId = async (userId: string) => {
    const request = await getUserMoments(userInfo.access_token, userId);
    setMomentsByUserId(request.res);
    setCurrentProgress(0);
  };

  const momentsUnseenRequest = async () => {
    const request = await getMomentsUnseen(userInfo.access_token, 10);
    console.log(request);
  };

  const momentsSeenRequest = async () => {
    const request = await getMomentSeen(userInfo.access_token);
    console.log(request);
  };

  const getMomentCreatorInfo = async (userId: string) => {
    const request = await getPublicDetails(userInfo.access_token, userId);
    setCreatorMomentInfo(request.res);

    setProfileAvatar(
      request.res.profile?.photos[0].thumbnailUrl ||
        request.res.profile?.photos[0].mediumUrl ||
        request.res.profile?.photos[0].url ||
        photoDefault
    );
  };

  const clickLikeDislikeMoment = useCallback(
    async (momentId: string) => {
      const currentMoment = momentsByUserId[currentProgress];

      if (currentMoment.hasLiked) {
        await deleteLikeMoment(userInfo.access_token, momentId);

        setMomentsByUserId((prevMoments) =>
          prevMoments.map((moment) =>
            moment.momentId === momentId
              ? {
                  ...moment,
                  hasLiked: false,
                  _count: {
                    ...moment._count,
                    likedBy: moment._count.likedBy - 1,
                  },
                }
              : moment
          )
        );
      } else {
        await postLikeMoment(userInfo.access_token, momentId);
        setMomentsByUserId((prevMoments) =>
          prevMoments.map((moment) =>
            moment.momentId === momentId
              ? {
                  ...moment,
                  hasLiked: true,
                  _count: {
                    ...moment._count,
                    likedBy: moment._count.likedBy + 1,
                  },
                }
              : moment
          )
        );
      }
    },
    [currentProgress, momentsByUserId, userInfo.access_token]
  );

  const handleTriggerCallback = (completed: boolean) => {
    setIsLoadingMoments(true);

    momentView(momentsByUserId[currentProgress].momentId);
    if (completed && currentProgress < momentsByUserId.length - 1) {
      setCurrentProgress((prev) => prev + 1);
    } else if (completed && currentProgress === momentsByUserId.length - 1) {
      const remainingIds = userIdList.filter((id) => id !== userIdShowing);
      setUserIdList(remainingIds);

      if (remainingIds.length > 0) {
        setUserIdShowing(remainingIds[0]);
      } else {
        setOpenUseModal(false);
      }
    }

    setIsLoadingMoments(false);
  };

  const closeModalHandler = () => {
    setOpenUseModal(false);
  };

  const playPauseMoment = (event: boolean) => {
    setIsPausedMoment(event);
  };

  const unlockMomentHandler = async (momentId: string) => {
    setIsLoadingMoments(true);
    try {
      const request = await postMomentPay(userInfo.access_token, momentId);

      switch (request.status) {
        case 201:
          setPaymentMomentStatus("success");
          setMomentAbleToShow(true);

          break;
        default:
          setPaymentMomentStatus("error");
          setMomentAbleToShow(false);

          break;
      }
    } catch (error) {
      console.log("error:", error);
      setPaymentMomentStatus("error");
    }
    setIsLoadingMoments(false);
  };

  const checkWalletHandler = () => {
    setOpenUseModal(false);
    setModalContent("");
    setPrincipalScreen("/user-settings-wallet");
  };

  const closeModalPaymentStatus = () => {
    setPaymentMomentStatus(undefined);
  };

  const markClickPosition = (e: React.MouseEvent) => {
    startX.current = e.clientX;
  };

  const markTouchPosition = (e: React.TouchEvent) => {
    startX.current = e.touches[0].clientX;
  };

  const markClickDrag = (e: React.MouseEvent) => {
    if (startX.current === null) return;

    const diffX = e.clientX - (startX.current || 0);
    if (diffX > 50) {
      // PREVIEW MOMENT
      startX.current = null;
    } else if (diffX < -50) {
      // NEXT MOMENT
      startX.current = null;
    }
  };

  const markTouchDrag = (e: React.TouchEvent) => {
    if (startX.current === null) return;

    const diffX = e.touches[0].clientX - (startX.current || 0);
    if (diffX > 50) {
      // PREVIEW MOMENT
      startX.current = null;
    } else if (diffX < -50) {
      // NEXT MOMENT
      startX.current = null;
    }
  };

  const markClickUpTouchEnd = () => {
    startX.current = null;
  };

  if (isLoadingMoments) {
    return (
      <div className="moments-track__loading-page-wrapper">
        <Loading />
      </div>
    );
  }

  if (paymentMomentStatus !== undefined) {
    return (
      <div className="moments-track__payment-result">
        {paymentMomentStatus === "error" ? (
          <div className="payment-result__payment-modal">
            <img src={CloseIconPink} alt="error icon" />
            <h3 className="payment-result__header no-margin">
              We had a problem with your payment.
            </h3>
            <p className="payment-result__explanation">
              Check your account and availability of Roses
            </p>
            <div className="margin-top-16 payment-result__buttons-container">
              <Button buttonStyle="primary" onClick={checkWalletHandler}>
                Check my wallet
              </Button>
              <Button
                buttonStyle="quaternary"
                onClick={closeModalPaymentStatus}
              >
                Cancel
              </Button>
            </div>
          </div>
        ) : (
          <div className="payment-result__payment-modal">
            <img src={CheckSimpleGreen} alt="success icon" />
            <h3 className="payment-result__header">Payment successful!</h3>
            <div className="margin-top-16 payment-result__buttons-container">
              <Button buttonStyle="primary" onClick={closeModalPaymentStatus}>
                Continue to moment
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <section
      id="moments-track"
      className={className ?? ""}
      onMouseDown={markClickPosition}
      onMouseMove={markClickDrag}
      onTouchStart={markTouchPosition}
      onTouchMove={markTouchDrag}
      onTouchEnd={markClickUpTouchEnd}
      onMouseUp={markClickUpTouchEnd}
    >
      <div className="moments-track__content-wrapper">
        <img src={CloseIcon} alt="close icons" onClick={closeModalHandler} />

        <div className="content-wrapper__progressbar-wrapper">
          {Array.from({ length: momentsByUserId.length }, (_, index) => (
            <Progressbar
              pause={isPausedMoment}
              key={index}
              time={
                momentsByUserId[currentProgress]?.file.fileType === "PHOTO"
                  ? 15000
                  : Math.round(
                      momentsByUserId[currentProgress]?.file.duration * 1000
                    )
              }
              trigger={index === currentProgress && !isLoadingMoments}
              triggerCallback={handleTriggerCallback}
            />
          ))}
        </div>

        <MomentsCards
          clickInMomentCallBack={playPauseMoment}
          className="content-wrapper__moments-cards"
          clickLikeMomentCallback={() =>
            clickLikeDislikeMoment(momentsByUserId[currentProgress].momentId)
          }
          unlockMomentCallback={() =>
            unlockMomentHandler(momentsByUserId[currentProgress].momentId)
          }
          creatorMomentInfo={{
            creatorAvatar: profileAvatar,
            creatorIsVip: creatorMomentInfo.isVip,
            creatorName: creatorMomentInfo.displayname,
            isPaidContent: momentAbleToShow,
            likesAmount: momentsByUserId[currentProgress]?._count.likedBy,
            viewersAmount: momentsByUserId[currentProgress]?._count.seenBy,
            media: momentsByUserId[currentProgress]?.file.url as string,
            postTime: momentsByUserId[currentProgress]?.createdAt,
            type:
              momentsByUserId[currentProgress]?.file.fileType === "VIDEO"
                ? "video"
                : "photo",
            creatorAge: creatorMomentInfo?.profile?.age,
            isMomentLiked: momentsByUserId[currentProgress].hasLiked,
            unlockPrice: momentsByUserId[currentProgress].price,
            momentIsAbleToShow: momentAbleToShow,
            momentId: momentsByUserId[currentProgress].momentId,
          }}
        />
      </div>
    </section>
  );
};

export default MomentsTrack;
