import React, { useRef, useState, useEffect } from "react";
import "./styles.scss";
import CloseIconWhite from "../../../assets/icons/closeIcons/close-icon-white.svg";
import RosesIconGray from "../../../assets/icons/logos/logo-rose-icon-pink.svg";
import MicIconGray from "../../../assets/icons/keyBoardIcons/mic-icon-gray.svg";
import CameraIconGray from "../../../assets/icons/camera-icon-gray.svg";
import MultiPhotoGray from "../../../assets/icons/multi-photo-gray.svg";
import { useModal } from "../../../hooks/useModal";
import AddMediaPage from "../../../pages/AddMediaPage";
import { usePublishContent } from "../../../hooks/usePublishContent";

interface ChatButtonProps {
  sendRoses: () => void;
  sendPack: (files: File[]) => void;
  sendAudio: () => void;
  openCamera: () => void;
  sendMedia: (files: File[]) => void;
  hasButtonExpansion?: boolean;
  isButtonsDisabled?: boolean;
  className?: string;
}

const ChatButton: React.FC<ChatButtonProps> = (props) => {
  const {
    sendRoses,
    sendPack,
    sendAudio,
    openCamera,
    sendMedia,
    hasButtonExpansion = true,
    isButtonsDisabled,
    className,
  } = props;

  const [isListButtonShow, setIsListButtonShow] = useState(false);
  const [isHidden, setIsHidden] = useState(true);
  const [fileType, setFileType] = useState("");
  const fileInputRefFront = useRef<HTMLInputElement>(null);
  const { setOpenUseModal, setModalContent } = useModal();
  const { setCreatorContent, clearPublishContent, creatorContent } =
    usePublishContent();

  const buttonsConfig = [
    {
      id: 0,
      supTextButton: "Roses",
      icon: RosesIconGray,
      alt: "roses logo gray",
      click: () => sendRoses(),
    },
    // Disabled indefinitely - 28/10/2024
    // {
    //   id: 1,
    //   supTextButton: "Pack",
    //   icon: PackIconGray,
    //   alt: "pack icon gray",
    //   click: () => {
    //     setFileType("pack");
    //     fileHandler();
    //     setIsListButtonShow(!isListButtonShow);
    //   },
    // },
    {
      id: 2,
      supTextButton: "Audio",
      icon: MicIconGray,
      alt: "mic icon gray",
      click: () => {
        sendAudio();
        setIsListButtonShow(!isListButtonShow);
      },
    },
    {
      id: 3,
      supTextButton: "Media",
      icon: MultiPhotoGray,
      alt: "camera icon gray",
      click: () => {
        setFileType("media");
        fileHandler();
        setIsListButtonShow(!isListButtonShow);
      },
    },

    {
      id: 4,
      supTextButton: "Camera",
      icon: CameraIconGray,
      alt: "photo icon gray",
      click: () => {
        openCamera();
        setIsListButtonShow(!isListButtonShow);
      },
    },
  ];

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList) {
      const filesArray = Array.from(fileList);

      switch (fileType) {
        case "media":
          sendMedia(filesArray);
          break;
        case "pack":
          sendPack(filesArray);
          break;
      }

      setIsListButtonShow(false);
      event.target.value = "";
    }
  };

  const fileHandler = () => {
    setTimeout(() => {
      fileInputRefFront.current?.click();
    }, 0);
  };

  useEffect(() => {
    if (isListButtonShow) {
      setIsHidden(false);
    }
  }, [isListButtonShow]);

  const handleAnimationEnd = (e: React.AnimationEvent<HTMLDivElement>) => {
    if (e.animationName === "slide-down" && !isListButtonShow) {
      setIsHidden(true);
    }
  };

  return (
    <div className={`chat-button-component ${className ?? ""}`}>
      <input
        type="file"
        name="upload-photo-front"
        ref={fileInputRefFront}
        accept={"video/*, .jpg,.jpeg,.png"}
        multiple={fileType === "pack"}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      {!isHidden && hasButtonExpansion && (
        <div
          className={`
        filter-container 
        padding-top-16
         ${isListButtonShow ? "show-buttons" : "hide-buttons"}
        `}
        >
          <div className="filter-list padding-hor-16">
            {buttonsConfig.map((button, index) => {
              return (
                <button
                  key={index}
                  className="filter-item"
                  onClick={button.click}
                >
                  <img src={button.icon} alt={button.alt} />
                  <p>{button.supTextButton}</p>
                </button>
              );
            })}
          </div>
          <button
            className="chat-button chat-button-close"
            onClick={() => setIsListButtonShow(!isListButtonShow)}
          >
            <span className="icon icon-md icon-close"></span>
            Select
            <span
              data-icon
              className={`icon icon-md icon-chevron-top rotate-image`}
            ></span>
          </button>
        </div>
        // <div
        //   className={`action-pills ${
        //     isListButtonShow ? "show-buttons" : "hide-buttons"
        //   }`}
        //   onAnimationEnd={handleAnimationEnd}
        // >
        //   {buttonsConfig.map((button) => (
        //     <div
        //       key={button.id}
        //       className={`action-buttons-wrapper ${button.supTextButton.toLowerCase()}-button ${
        //         isListButtonShow ? "show-buttons" : "hide-buttons"
        //       }`}
        //     >
        //       <button
        //         disabled={isButtonsDisabled}
        //         onClick={button.click}
        //         className="action-button_button padding-hor-16"
        //       >
        //         <img
        //           src={button.icon}
        //           alt={button.alt}
        //           className="action-button_icon-button"
        //         />
        //         <p className="no-margin">{button.supTextButton}</p>
        //       </button>
        //     </div>
        //   ))}
        //   <div
        //     className={`action-buttons-wrapper ${
        //       isListButtonShow ? "show-buttons" : "hide-buttons"
        //     }`}
        //   >
        //     <button
        //       disabled={isButtonsDisabled}
        //       onClick={() => ""}
        //       className="action-button_button padding-hor-16"
        //     >
        //       <img src={""} alt={""} className="action-button_icon-button" />
        //       <p className="no-margin">Select</p>
        //     </button>
        //   </div>
        // </div>
      )}
      <button
        disabled={isButtonsDisabled}
        onClick={() => setIsListButtonShow(!isListButtonShow)}
        className="chat-button"
      >
        <span className="icon icon-md icon-photo"></span>
        Select
        <span data-icon className={`icon icon-md icon-chevron-top`}></span>
      </button>
    </div>
  );
};

export default ChatButton;
