import React from "react";
import "./styles.scss";
import ReadAudioChat from "../../ReadAudioChat";
import LockedFilesChat from "../../LockedFilesChat";
import ImageBlur from "../../../assets/images/image-blur.jpg";
import playIcon from "../../../assets/icons/play-circle-white.svg";
import CardRosesSendedAndReceived from "../../Cards/CardRosesSendedAndReceived";
import AudioWaveform from "../../AudioWave";

interface IChatMessage {
  side: string;
  message?: string;
  time?: string;
  translateDisclaimerContent?: string;
  fileSended?: IFile[];
  clickOnImage?: (a: any) => void;
  clickOnVideo?: (a: any) => void;
  clickOnPack?: (file: IFile[]) => void;
  chatRef?: React.LegacyRef<HTMLDivElement> | undefined;
  messageSettings: {
    messageSettingsId: string;
    messageId: string;
    paidContent: boolean;
    price: number;
    isPaid: boolean;
  };
  unlockFileCallback: (a: string) => void;
  isMessageRead: boolean;
}

interface IFile {
  url: string;
  fileType: string;
  mimetype: string;
  duration?: number;
}

const ChatMessage: React.FC<IChatMessage> = ({
  side,
  message,
  time,
  translateDisclaimerContent,
  fileSended,
  clickOnImage,
  clickOnVideo,
  clickOnPack,
  chatRef,
  messageSettings,
  unlockFileCallback,
  isMessageRead,
}: IChatMessage) => {
  const unlockFile = () => unlockFileCallback(messageSettings.messageId);
  const isPaid = !!messageSettings?.isPaid;
  const paidContent = messageSettings?.paidContent;
  const files = fileSended?.length ? fileSended : [];

  const classNameForFileWrapper = () => {
    if (!files.length) {
      return `text-message-wrapper ${side}-layout`;
    } else if (files[0]?.fileType === "AUDIO") {
      return `audio-file-wrapper ${side}-layout`;
    } else if (side === "left" && paidContent && !isPaid) {
      return `blocked-files-wrapper ${side}-layout`;
    } else if (files.length < 4) {
      return `single-file-wrapper ${side}-layout`;
    } else if (files.length >= 4) {
      return `file-grid-wrapper ${side}-layout`;
    } else if (!files.length) {
      return `text-message-container`;
    }
  };

  const renderLockedContent = (unlockCallback: () => void) => (
    <LockedFilesChat
      rosesPrice={messageSettings.price}
      unlockClick={unlockCallback}
      message="Unlock this content"
      lockedImageBlurred={ImageBlur}
      qntPhotos={
        files?.filter((file) => file.fileType === "PHOTO").length || "0"
      }
      qntVideos={
        files?.filter((file) => file.fileType === "VIDEO").length || "0"
      }
    />
  );

  const renderFileContent = (file: IFile, index: number) => {
    if (file?.fileType === "PHOTO") {
      return side === "left" && paidContent && !isPaid ? (
        renderLockedContent(unlockFile)
      ) : (
        <div className="photo-content-container">
          <img
            key={`${messageSettings.messageSettingsId}${index}`}
            src={file.url}
            alt={file.url}
            className={`chat-file file-${side}`}
            onClick={() => {
              if (clickOnImage) {
                if ((paidContent && isPaid) || !paidContent) {
                  clickOnImage(file.url);
                } else if (side === "right") {
                  clickOnImage(file.url);
                }
              }
            }}
          />
        </div>
      );
    }

    if (file?.fileType === "VIDEO") {
      return side === "left" && paidContent && !isPaid ? (
        renderLockedContent(unlockFile)
      ) : (
        <div className={`video-content-container`}>
          <img src={playIcon} alt="play icon" className="play-icon" />
          <video
            key={`${messageSettings.messageSettingsId}${index}`}
            className={`chat-file file-${side}`}
            onClick={() => {
              if (clickOnVideo) {
                if ((paidContent && isPaid) || !paidContent) {
                  clickOnVideo(file.url);
                } else if (side === "right") {
                  clickOnVideo(file.url);
                }
              }
            }}
          >
            <source src={file.url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      );
    }

    if (file?.fileType === "AUDIO") {
      return side === "left" && paidContent && !isPaid ? (
        renderLockedContent(unlockFile)
      ) : (
        <div className={`audio-content-container`}>
          <AudioWaveform audioSrc={file.url} duration={file.duration} />
        </div>
      );
    }
  };

  const renderGridFilesContent = () => {
    if (side === "right" || isPaid) {
      return (
        <>
          {files?.slice(0, 4).map((file, index) => (
            <div className={`file-chat-wrapper ${side}`} key={index}>
              {files.length > 4 && index + 1 === 4 ? (
                <div className={`files-remains ${side}`}>
                  <p className="files-remains-text">+ {files.length - 3}</p>
                </div>
              ) : (
                <>
                  {file.fileType === "PHOTO" ? (
                    <div className="photo-content-container">
                      <img
                        key={`${messageSettings.messageSettingsId}${index}`}
                        src={file.url}
                        alt={file.url}
                        className={`chat-file file-${side}-${index + 1}`}
                      />
                    </div>
                  ) : (
                    file.fileType === "VIDEO" && (
                      <div className={`video-content-container 2`}>
                        <img
                          src={playIcon}
                          alt="play icon"
                          className="play-icon"
                        />
                        <video
                          key={`${messageSettings.messageSettingsId}${index}`}
                          className={`chat-file file-${side}-${index + 1}`}
                        >
                          <source src={file.url} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    )
                  )}
                </>
              )}
            </div>
          ))}
        </>
      );
    } else {
      renderLockedContent(unlockFile);
    }
  };

  return (
    <div
      className={`chat-message-component margin-bottom-8 messages-${side}`}
      ref={chatRef}
      onClick={() => {
        if (files.length >= 4 && clickOnPack && (isPaid || side === "right")) {
          clickOnPack(files);
        }
      }}
    >
      {side === "left" && (
        <p className="message-info-container__time no-margin">{time}</p>
      )}

      <div className={`${classNameForFileWrapper()}`}>
        {files.length >= 4 && !isPaid && side === "left" ? (
          renderLockedContent(unlockFile)
        ) : files.length >= 4 ? (
          renderGridFilesContent()
        ) : (
          <>
            {!!files?.length
              ? files.map((file, index) => (
                  <div className="file-chat-wrapper" key={index}>
                    {renderFileContent(file, index)}
                  </div>
                ))
              : ""}
          </>
        )}
  
        {side === "left" &&
          paidContent &&
          !isPaid &&
          files[0]?.fileType !== "AUDIO" ? (
          <></>
        ) : (
          message && (
            <div className="message-info-container">
              <p className="no-margin message-info-container__message">
                {message}
              </p>
            </div>
          )
        )}
      </div>
  
      {side === "right" && (
        <p className="message-info-container__time no-margin">
          {time}
          <span
            className={`read-message-status ${
              isMessageRead ? "message-read" : "unread-message"
            }`}
          />
        </p>
      )}
  
      {translateDisclaimerContent && (
        <span className={`disclaimer-container ${side}`}>
          {translateDisclaimerContent}
        </span>
      )}
    </div>
  );
  
}
export default ChatMessage;
