import React, { useEffect, useState } from "react";
import "./styles.scss";
import PageTitle from "../../components/PageTitles";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import InputText from "../../components/Inputs/InputText";
import { useUserInfo } from "../../hooks/userInfo";
import Button from "../../components/Buttons/Button";
import InputMessage from "../../components/TextAreas/Textarea";
import language from "../../mocks/language";
import patchProfileDetails from "../../api/profileDetails/patchProfileDetails";
import getUserInfo from "../../api/userInfo/getUserInfo";
import { UserInfoRequest } from "../../types/userInfoRequest";

interface ListItemBioProps {
  tokenUser?: string;
  key?: string;
  valueb?: string;
}
const UserSettingsEditBioPage: React.FC<ListItemBioProps> = () => {
  const { setPrincipalScreen } = useDeskNavigation();
  const { userInfo } = useUserInfo();
  const [value, setValue] = useState("");
  const [userInfoRequest, setUserInfoRequest] =
    useState<UserInfoRequest | null>(null);

  useEffect(() => {
    requestUserInfo();

    console.log(value, userInfoRequest?.bio, "bio");
  }, [userInfo, userInfoRequest?.bio]);

  const requestUserInfo = async () => {
    const response = await getUserInfo(userInfo.access_token);
    setUserInfoRequest(response.res);
    if (userInfoRequest) {
      setValue(userInfoRequest.bio);
    }
  };

  const saveEditHandler = async () => {
    await patchProfileDetails(userInfo.access_token, "bio", value);
  };

  return (
    <div className="user-settings-edit-profile">
      <PageTitle
        title={"Edit Profile"}
        subtitle={"Configuration"}
        hasCloseIcon={false}
        onBackClick={() => setPrincipalScreen("/user-settings-profile")}
      />
      <main className="user-settings-edit-profile__main margin-24">
        <div className="user-settings-edit-profile__input-container">
          <InputMessage
            value={value}
            onChangeInput={(e) => setValue(e)}
            label="Bio"
            placeholder={"About me"}
          />
        </div>

        <Button
          className="margin-top-40"
          buttonStyle="primary"
          onClick={saveEditHandler}
        >
          Done
        </Button>
      </main>
    </div>
  );
};

export default UserSettingsEditBioPage;
