import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import photoDefault from "../../../assets/images/roses-photo-default.png";
import HeartIconSolidPink from "../../../assets/icons/heartIcons/heart-icon-solid-pink.svg";
import HeartIcoWhite from "../../../assets/icons/heartIcons/heart-white.svg";
import { abbreviationForLargeNumbers } from "../../../utils/abbreviationForLargeNumbers";
import { formatTimeFromNowToDate } from "../../../utils/formatTimeFromNowToDate";
import Button from "../../Buttons/Button";

interface MomentsCardsProps {
  className?: string;
  clickLikeMomentCallback: () => void;
  creatorMomentInfo: ICreatorMomentInfo;
  clickInMomentCallBack: (a: boolean) => void;
  unlockMomentCallback: () => void;
}

type ICreatorMomentInfo = {
  type: "photo" | "video";
  media: string;
  creatorName: string;
  creatorAge?: string | number;
  momentId: string;
  // videosAmount?: string | number;
  // photosAmount?: string | number;
  likesAmount: number;
  postTime: Date;
  creatorAvatar: string;
  creatorIsVip: boolean;
  isPaidContent: boolean;
  viewersAmount: number;
  isMomentLiked: boolean;
  unlockPrice: number;
  momentIsAbleToShow: boolean;
};

const MomentsCards: React.FC<MomentsCardsProps> = (props) => {
  const {
    className,
    creatorMomentInfo,
    clickLikeMomentCallback,
    clickInMomentCallBack,
    unlockMomentCallback,
  } = props;

  const [isLiked, setIsLiked] = useState(false);
  const [likesNumber, setLikesNumber] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const videoCapturedRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const videoElement = videoCapturedRef.current;

    if (
      creatorMomentInfo.type === "video" &&
      creatorMomentInfo.momentIsAbleToShow &&
      videoElement
    ) {
      videoElement.play().catch((error) => {
        if (error.name !== "AbortError") {
          console.error(error);
        }
      });
    }

    return () => {
      if (videoElement) {
        videoElement.pause();
      }
    };
  }, [creatorMomentInfo]);

  useEffect(() => {
    if (videoCapturedRef.current) {
      if (isPaused) {
        videoCapturedRef.current.pause();
      } else {
        videoCapturedRef.current.play().catch((error) => {
          console.error("error", error);
        });
      }
    }
  }, [isPaused]);

  const clickLikeMomentHandler = () => {
    const hasLiked = !isLiked;
    setIsLiked(hasLiked);
    setLikesNumber(() => (hasLiked ? likesNumber + 1 : likesNumber - 1));
    clickLikeMomentCallback();
  };

  const momentStop = () => {
    if (creatorMomentInfo.type === "video") {
      setIsPaused(true);
    }
    clickInMomentCallBack(true);
  };

  const momentContinue = () => {
    if (creatorMomentInfo.type === "video") {
      setIsPaused(false);
    }
    clickInMomentCallBack(false);
  };

  return (
    <section id="moments-cards" className={className ?? ""}>
      <div
        className="moments-cards__moment-wrapper"
        onMouseDown={(e) => {
          e.preventDefault();
          momentStop();
        }}
        onMouseUp={(e) => {
          e.preventDefault();
          momentContinue();
        }}
        onMouseLeave={() => momentContinue()}
      >
        {creatorMomentInfo.type === "photo" ||
        !creatorMomentInfo.momentIsAbleToShow ? (
          <img
            src={creatorMomentInfo.media}
            alt="media moment"
            className="moment-wrapper__media-photo"
          />
        ) : (
          <div className="moment-wrapper__media-video">
            <video
              key={creatorMomentInfo.media}
              ref={videoCapturedRef}
              autoPlay
              className="media-video__video"
            >
              <source src={creatorMomentInfo.media} type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </div>
        )}
      </div>
      {!creatorMomentInfo.isPaidContent ? (
        <div className="profile-title">
          <div className="user-info">
            <img
              className="profile-img"
              src={creatorMomentInfo.creatorAvatar || photoDefault}
              alt="profile"
              loading="lazy"
            />
            <div className="user-title">
              <p className="profile-info no-margin">
                {creatorMomentInfo.creatorName}
              </p>
              {creatorMomentInfo.creatorIsVip && (
                <span className="icon icon-burn icon-md"></span>
              )}
            </div>
            <p className="counter">{creatorMomentInfo.creatorAge} years</p>

            <div className="profile-statistics">
              <div className="statistics-info">
                <span className="icon icon-clock icon-sm icon-gray"></span>
                <p className="counter">
                  {formatTimeFromNowToDate(creatorMomentInfo.postTime)}
                </p>
              </div>

              <div className="statistics-info">
                <span className="icon icon-eye icon-sm icon-gray"></span>
                <p className="counter">{creatorMomentInfo.viewersAmount}</p>
              </div>

              <div className="statistics-info">
                <span className="icon icon-heart-solid icon-sm icon-gray"></span>
                <p className="counter">
                  {abbreviationForLargeNumbers(likesNumber)}
                </p>
              </div>

              <div className="statistics-info">
                <span className="icon icon-roses-outline  icon-sm icon-gray"></span>
                <p className="counter">{creatorMomentInfo.unlockPrice}</p>
              </div>
            </div>
            <Button
              buttonStyle="secondary"
              className="margin-top-16"
              onClick={unlockMomentCallback}
            >
              Unlock this moment
            </Button>
          </div>
        </div>
      ) : (
        <>
          <aside className="moments-details__interaction-wrapper">
            <div className="interaction-wrapper__likes-button-amount-view">
              <button
                className="button likes-button-amount-view__button"
                onClick={clickLikeMomentHandler}
              >
                <img
                  src={isLiked ? HeartIconSolidPink : HeartIcoWhite}
                  alt="heart icon"
                />
              </button>
              <p className="no-margin likes-button-amount-view__amount">
                {abbreviationForLargeNumbers(likesNumber)}
              </p>
            </div>
          </aside>
          <footer className="moments-details__creator-moments-details">
            <div className="creator-moments-details__profile-avatar">
              <img
                src={creatorMomentInfo.creatorAvatar}
                alt={`${creatorMomentInfo.creatorName} avatar`}
                className="profile-avatar__image"
              />
            </div>
            <p className="no-margin creator-moments-details__profile-name">
              {creatorMomentInfo.creatorName}
              {creatorMomentInfo.creatorIsVip && (
                <span className="icon icon-burn icon-md"></span>
              )}

              <span className="profile-name__post-time">
                {formatTimeFromNowToDate(creatorMomentInfo.postTime)}
              </span>
            </p>
          </footer>
        </>
      )}
    </section>
  );
};

export default MomentsCards;
