export const abbreviationForLargeNumbers = (num: string | number) => {
  if (num === 0 || !num) {
    return "0";
  }
  num = Number(num);

  if (num >= 1_000 && num < 1_000_000) {
    return (num / 1_000).toFixed(1).replace(/\.0$/, "") + "K";
  } else if (num >= 1_000_000) {
    return (num / 1_000_000).toFixed(1).replace(/\.0$/, "") + "M";
  } else {
    return num.toString();
  }
};
