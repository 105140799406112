import fetchApi from "../fetchApi";

const getMomentsUnseen = async (
  token: string,
  take?: number,
  cursor?: string
) => {
  const params: Record<string, any> = {};

  if (cursor || cursor !== undefined) {
    params.cursor = cursor;
  }

  if (take || cursor !== undefined) {
    params.take = take;
  }
  return await fetchApi({
    service: "moments",
    method: "GET",
    endPoint: `/unseen`,
    params,
    token,
  });
};

export default getMomentsUnseen;
